/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: zhengluming
 * @Date: 2023-06-21 11:47:07
 * @LastEditors: zhengluming
 * @LastEditTime: 2023-08-28 17:06:47
 */
import produce from "immer";
import { create } from "zustand";
import { userAPI } from "@/service";

/**
 * 可下载 Redux DevTools 查看
 * https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd/related?hl=zh-CN
 */
import { devtools } from "zustand/middleware";

const useGlobalStore = create(
  devtools(
    (set, get) => ({
      /**
       * 当前登陆人信息
       */
      user: {
        nick: "蜡笔小波波",
        avatar: "",
        id: null,
        midou: 0,
        phone: "",
        recommendTicket: 0,
        unread: 0
      },
      isLogin: false, // 是否登陆
      /** 章节页主题 */
      chapterTheme: 0,

      /** 更新左侧菜单状态 */
      setLoginUser: useInfo => {
        set(
          produce(state => {
            state.user = useInfo;
            state.isLogin = true;
          })
        );
      },
      /** 更新用户信息（打赏后更新余额）*/
      getUser: () => {
        userAPI.userInfo().then(res => {
          if (res.code === 0 && res.result) {
            get().setLoginUser(res.result);
          }
        });
      },
      handleLogout: () => {
        set(
          produce(state => {
            state.user = { avatar: "", midou: "", phone: "" };
            state.isLogin = false;
          })
        );
      },
      setChapterTheme: v => {
        set(
          produce(state => {
            state.chapterTheme = v;
          })
        );
      }
    }),
    // 自定义 redux devtools 实例名
    { name: "useGlobalStore" }
  )
);

export default useGlobalStore;
