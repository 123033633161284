/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: zhengluming
 * @Date: 2023-05-24 10:45:42
 * @LastEditors: zhengluming
 * @LastEditTime: 2023-08-30 14:14:20
 */
import request from "@/utils/request";

export const userAPI = {
  userInfo: () => request.get("user/info"),
  uploadStsCredential: (param) => request.get("upload/stsCredential", param),
  updateUserInfo: (param) => request.post("user/update", param),
}

export const productAPI = {
  getProductList: () => request.get("product/list")
};

export const orderAPI = {
  createOrder: data => request.post("order/create", data),
  orderList: param => request.get("order/list", param),
  orderConfirm: param => request.post("order/confirm", param),
  orderStatus: param => request.get("order/status", param)
};

export const subBookAPI = {
  /** 作品订阅列表 */
  subBookList: param => request.get("subBook/list", param)
};

export const bookAPI = {
  bookDetail: param => request.get("book/detail", param),
  /** 订阅书 */
  bookSub: id => request.post("book/sub", { id }),
  /** 书订阅信息（登陆人待订阅总章节数和总花费波星币） */
  bookSubInfo: id => request.get("book/subInfo", { id })
};

export const chapterAPI = {
  /** 订阅章节 */
  startSub: id => request.post("chapter/sub", { id }),
  /** 章订阅 */
  subChapterList: param => request.get("subChapter/list", param)
};

export const rewardAPI = {
  /** 打赏作品明细 */
  rewardBookStatementList: params => request.get('rewardBookStatement/list', params),
  /** 打赏作品列表 */
  rewardBookList: params => request.get('rewardBook/list', params),
  /** 打赏作品 */
  rewardBook: params => request.post('book/reward', params),
}

/** 推荐票 */
export const recommendAPI = {
  /** 阅读累计超30min送票，每月仅一张 */
  sendTicketForRead: () => request.get('recommendTicket/createForRead'),
  /** 推荐票列表 */
  ticketList: (param) => request.get('recommendTicket/list', param),
  /** 投票明细 */
  recommendBookStatementList: (param) => request.get('recommendBookStatement/list', param),
  /** 推荐作品列表 */
  recommendBookList: (param) => request.get('recommendBook/list', param),
  /** 投票 */
  vote: (param) => request.post('book/vote', param),
}

/** 我的评论 */
export const myCommentAPI = {
  /** 我的书评列表  */
  totalCommentList: (param) => request.get('commentBook/list', param),
  /** 我的书评列表 detail todo */
  detailCommentList: (param) =>  request.get('comment/mine/list', param),
  /** 评论列表 */
  bookCommentList: (param) => request.get('comment/list', param),
  /** 评论 */
  postComment: (param) => request.post('book/comment', param),
  /** 点赞 */
  likeComment: (param) => request.post('comment/like', param),
  /** 点踩 */
  dislikeComment: (param) => request.post('comment/dislike', param),
  /** 删除 */
  deleteComment: (param) => request.post('book/comment/delete', param),
}

/** 我的书架 */
export const myBookshelfAPI = {
  /** 我的书架列表 */
  myBookshelfList: () => request.get('bookshelf/list'),
}

/** 消息通知 */
export const notifyAPI = {
  /** 投诉  */
  submitTipOff: (param) => request.post('report/create', param),
  /** 消息列表 */
  notifyList: (param) => request.get('message/list', param),
}