import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import Router from "@/router";
import styles from "./index.module.scss";
import Header from "@component/header";
import Footer from "@component/footer";
import { colorConfigArray } from "@/utils/constant";

export default function PageLayout() {
  const location = useLocation();
  const { pathname } = location;
  const bgType = localStorage.getItem("bgType") || "0";

  const [colorConfig, setColorConfig] = useState(
    colorConfigArray[
      bgType && (bgType == 1 || bgType == 2 || bgType == 3) ? bgType : 0
    ] || {}
  );

  var originalSetItem = localStorage.setItem;
  localStorage.setItem = function() {
    const event = new Event("itemInserted");
    originalSetItem.apply(this, arguments);
    document.dispatchEvent(event);
  };

  const localStorageSetHandler = function(e) {
    if (localStorage.getItem("bgType") !== null) {
      setColorConfig(colorConfigArray[localStorage.getItem("bgType")]);
    }
  };
  document.addEventListener("itemInserted", localStorageSetHandler, false);

  useEffect(() => {
    if (!window.location.href.includes(document.referrer)) {
      myAnaly.track("access", { platform: 1 }, "high"); //自定义打点;
    }
  }, []);
  const noLayout =
    pathname.includes("/login") ||
    pathname.includes("/personalAgreement") ||
    pathname.includes("/authorAgreement") ||
    pathname.includes("/userAgreement");

  return (
    <Layout>
      {!noLayout ? <Header /> : null}
      <Layout
        className={styles.main}
        style={{
          background: pathname.includes("/chapter/")
            ? colorConfig?.outsideBackground
            : "#fff",
          paddingTop: noLayout ? 0 : "64px"
        }}
      >
        <Router />
      </Layout>
      {!noLayout ? (
        <Footer
          background={
            pathname.includes("/chapter/")
              ? colorConfig?.outsideBackground
              : "#f6f7fb"
          }
          color={
            pathname.includes("/chapter/")
              ? colorConfig?.footerColor
              : "#acaeb0"
          }
        />
      ) : null}
    </Layout>
  );
}
