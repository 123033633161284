import { useState, useCallback, useMemo } from "react";

/**
 * 为了实现路由守卫，本地在登陆相关操作时，执行一下逻辑，在本地维护一个状态。
 * 路由守卫逻辑见 src/router/index.jsx AuthGard 方法
 */
const STORAGE_KEY = "mepo_login";

const useLoginAuth = () => {
  const value = localStorage.getItem(STORAGE_KEY);
  const [isLogin, setIsLogin] = useState(value === "true");

  const setLogin = useCallback(() => {
    localStorage.setItem(STORAGE_KEY, "true");
    setIsLogin(true);
  }, []);

  const removeLogin = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    setIsLogin(false);
  }, []);

  return useMemo(() => ({ isLogin, setLogin, removeLogin }), [isLogin]);
};

export default useLoginAuth;
