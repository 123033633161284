/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: zhengluming
 * @Date: 2023-05-24 11:10:06
 * @LastEditors: zhengluming
 * @LastEditTime: 2023-08-30 13:58:31
 */
import React, { useState, useCallback, useEffect, useRef } from "react";
import { Pagination, message } from "antd";
import IconAll from "@/images/icon-all.png";
import IconAllOn from "@/images/icon-all-on.png";
import IconTipOff from "@/images/icon-tip-off.png";
import IconTipOffOn from "@/images/icon-tip-off-on.png";
import IconOther from "@/images/icon-other.png";
import IconOtherOn from "@/images/icon-other-on.png";
import IconNoData from "@/images/no-notify.png";
import useGlobalStore from "@/stores";

import { formatDate } from "@/utils";

import { notifyAPI } from "@/service";
import { useRequest } from "ahooks";

import "../index.scss"

const tabs = [
    { icon: IconAll, iconOn: IconAllOn, name: "全部", id: 1 },
    { icon: IconTipOff, iconOn: IconTipOffOn, name: "举报", id: 2 },
    { icon: IconOther, iconOn: IconOtherOn, name: "其他", id: 3 },
]

const notifyList = [
    { content: '经核实，书籍《三三的第一本书》内容存在严重抄权情况，对举报书辖发红牌违规警告，对涉及章节进行锁定处理，且举报书籍永久不得登上咪波官方人工推荐傍单，请及时修改，申请解锁。', time: '2023-03-22 15:22:22', id: 1 },
    { content: '经核实，发现书籍《经核实，发现书籍《XXX书籍名称》确实存在伪更情况，对该内容进行锁定处理，扣除作者全勒奖，且举报书籍永久不得登上咪波官方人工推荐榜单，请及时修改，申请解锁。》确实存在伪更情况，对该内容进行锁定处理，扣除作者全勒奖，且举报书籍永久不得登上咪波官方人工推荐榜单，请及时修改，申请解锁。', time: '2023-03-22 15:22:22', id: 2 }
]

const Balance = () => {

    return (
        <div
            className="flex justify-between border-dashed border-0 border-b pb-5 mb-2"
            style={{ borderColor: "#EFEFEF" }}
        >
            <div>消息通知</div>
        </div>
    );
};

const Notify = () => {
    const [tabIndex, setTabIndex] = useState(1);
    const [data, setData] = useState({ count: 0, list: [] });
    const [pageVo, setPageVo] = useState({ pageSize: 10, pageNo: 1 });

    const [getUser] = useGlobalStore(s => [
        s.getUser
      ]);

    useEffect(() => {
        let param = {
            ...pageVo,
        }
        switch(tabIndex){
            case 1: // 全部
                break;
            case 2: // 举报
                param.type = 0;
                break;
            case 3: // 其他
                param.other = true;
                break;
        }
        fetchList(param);
    }, [tabIndex, pageVo])

    const { loading, run: fetchList } = useRequest(
        param => notifyAPI.notifyList(param),
        {
            manual: true,
            onSuccess: res => {
                if (res.code == 0) {
                    const list = res.result?.list;
                    const count = res.result?.count;
                    setData({ count, list });
                    getUser();
                } else {
                    message.warning(res.message);
                }
            }
        }
    );

    const onPageChange = useCallback((pageNo, pageSize) => {
        let page = { pageNo, pageSize };
        setPageVo(page);
    }, []);

    return (
        <div className="pb-24 w-full">
            <Balance />
            <div className="notify-container">
                <div className="notify-tabs mt-4">
                    <div className="m-auto flex justify-between" style={{ width: '296px' }}>
                        {
                            tabs.map(tab => {
                                return <div key={tab.id}
                                    className={`notify-tab text-center ${tabIndex == tab.id ? 'notify-tab-on' : ""} w-12 pb-2`}
                                    onClick={() => {
                                        setPageVo({...pageVo, pageNo: 1})
                                        setTabIndex(tab.id)
                                    }}
                                >
                                    <img className="w-12 " src={tabIndex == tab.id ? tab.iconOn : tab.icon} alt="" />
                                    <span className="w-12 mt-3">{tab.name}</span>
                                </div>
                            })
                        }
                    </div>
                </div>
                {data.count > 0 ? <div className="notify-list">
                    {
                        data?.list?.map(notify => {
                            return <div className="notify-item" key={notify.id}>
                                <div className="notify-item-content">{notify.content}</div>
                                <div className="notify-item-time">{formatDate(notify.createTime)}</div>
                            </div>
                        })
                    }
                </div> : <div className="no-notify-container">
                    <img src={IconNoData} alt="" />
                    <span>您暂时还未有任何消息</span>
                </div>}
                {data.count > 0 ? <div className="flex justify-between mt-10 text-t-secondary">
                    <span className="text-t-secondary">共 {data.count} 条记录</span>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        total={data.count}
                        pageSize={pageVo.pageSize}
                        current={pageVo.pageNo}
                        onChange={onPageChange}
                    />
                </div> : null}
            </div>
        </div>
    );
};

export default Notify;
