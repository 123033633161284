import request from "@/utils/request";

var getAuthor = function(params) {
  return request.get("user/info", params);
};
const logoutHandle = function(params) {
  return request.post("user/logout", params);
};

export { getAuthor, logoutHandle };
