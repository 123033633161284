export const noop = () => {};
export const emptyObj = {};
export const emptyArr = [];

export const PAGE_CONTAINER_ID = "PAGE_CONTAINER_ID";

export const coverCutReg = "imageMogr2/crop/450x600/gravity/center";
export const cdnPath = "https://imepo-1310028400.file.myqcloud.com/";

export const confirmImageCutReg = "imageMogr2/crop/600x450/gravity/center";

export const minLineHeight = 20;
export const minFontSize = 12;
export const colorConfigArray = [
  {
    outsideBackground: "#F6F7FB",
    background: "#ffffff",
    color: "#494949",
    btnBackground: "#22CC76",
    btnBorderColor: "#22CC76",
    btnColor: "#ffffff",
    footerColor: "#ACAEB0"
  },
  {
    outsideBackground: "#D9CDB6",
    background: "#FAEED7",
    color: "#4F3718",
    btnBackground: "#C7BCA1",
    btnBorderColor: "#C7BCA1",
    btnColor: "#ffffff",
    footerColor: "#998D6C"
  },
  {
    outsideBackground: "#D4E3D0",
    background: "#F5FAF2",
    color: "#2A4024",
    btnBackground: "#1EBF6E",
    btnBorderColor: "#1EBF6E",
    btnColor: "#ffffff",
    footerColor: "#6C9983"
  },
  {
    outsideBackground: "#242424",
    background: "#3E3F41",
    color: "#A5A5A5",
    btnBackground: "#22CC76",
    btnBorderColor: "#22CC76",
    btnColor: "#ffffff",
    footerColor: "#6B6A6A"
  }
];
export const blackColorConfig = {
  background: "#242424",
  btnBackground: "#1EBF6E",
  btnBorderColor: "#1EBF6E",
  btnColor: "#ffffff"
};
export const darkModeColor = "#8C8C8C";

export const PAY_TYPE_ENUM = {
  0: "微信支付",
  1: "支付宝"
};

export const ORDER_STATUS_ENUM = {
  0: "等待支付",
  1: "确认订单",
  2: "支付成功"
};

/** 推荐票获取方式 */
export const TICKET_CHANNEL_ENUM = {
  0: "阅读时长",
  1: "精华评论",
  2: "累计消费"
};
