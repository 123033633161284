import React, { useState, useEffect, useMemo, useCallback } from "react";
import styles from "./index.module.scss";
import './index.scss'

import {
  useLocation,
  useNavigate,
  useSearchParams,
  NavLink
} from "react-router-dom";
import { getAuthor, logoutHandle } from "./service";
import useGlobalStore from "@/stores";
import { useLoginAuth } from "@/hooks";

/* img */
import Logo from "@/images/logo.png";
import UserIcon from "@/images/user.png";
// import UserIcon from "@/images/icon-default-avatar.png";
import notify99 from "@/images/notify99.png";

import { cdnPath } from "@/utils/constant";

import { Input, Modal, message } from "antd";
const { Search } = Input;

export const Index = () => {
  const [setLoginUser, handleLogout, unreadCount ] = useGlobalStore(s => [
    s.setLoginUser,
    s.handleLogout,
    s.user.unread
  ]);
  const { setLogin, removeLogin } = useLoginAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [searchParams, setSearchParams] = useSearchParams();
  const keywords = searchParams.get("keywords");
  const [search, setSearch] = useState(keywords);
  const [username, setUsername] = useState();
  const [userIcon, setUserIcon] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const linksItem = useMemo(() => {
    return (
      <div className="self-center text-white mx-5">
        <NavLink className={`cursor-pointer hover:text-primary top-nav-link `} to={"/recharge"}>
          充值
        </NavLink>
        <NavLink className={`cursor-pointer hover:text-primary top-nav-link top-notify`} to={"/user/notify"}>
          <span>
            消息
            {unreadCount <= 0 ? null : (
              unreadCount <= 99 ? <span className="notify-count">{unreadCount}</span> :
              <img src={notify99} className="notify99" alt="" />
            )}
          </span>

        </NavLink>
      </div>
    );
  }, [unreadCount]);

  useEffect(() => {
    setSearch(keywords);
  }, [keywords]);
  useEffect(() => {
    getAuthorHandle();
  }, []);
  const getAuthorHandle = () => {
    getAuthor().then(res => {
      if (res.code === 0) {
        const { phone, avatar } = res.result;
        setLoginUser(res.result);
        setUsername(phone);
        setUserIcon(avatar)
        setLogin();
      } else {
        removeLogin();
      }
    });
  };
  const handleOk = () => {
    logoutHandle().then(res => {
      if (res.code === 0) {
        removeLogin();
        handleLogout();
        navigate("/login");
      } else {
        message.error(res.message);
        setIsModalOpen(false);
      }
    });
  };

  const handleSearch = (val) => {
    const text = val.trim()
    navigate(`/search?t=${new Date().getTime()}&keywords=${text}`)
  }

  return (
    <div className={styles.headerMain}>
      <div className={styles.content}>
        <div className={styles.left}>
          <img src={Logo} alt="" onClick={() => navigate("/")} />
          <div
            className={`${styles.tab} ${
              pathname === "/" ||
              pathname.includes("/product") ||
              pathname.includes("/search")
                ? styles.active
                : ""
            }`}
            onClick={() => navigate("/")}
          >
            首页
          </div>
          <div
            className={styles.tab}
            onClick={() => {
              window.location.href = "https://author.imepo.cn";
            }}
          >
            作者专区
          </div>
          <div
            className={styles.tab}
            onClick={() => {
              window.location.href = "https://www.imepo.cn/music/";
            }}
          >
            音乐专区
          </div>
          <div
            className={`${styles.tab} ${
            pathname === "/policy"
              ? styles.active
              : ""
            }`}
            onClick={() => {
              navigate(`/policy`)
            }}
          >
            签约政策
          </div>
        </div>
        <Search
          placeholder="请输入书籍名称"
          enterButton="搜索"
          className={styles.searchIpt}
          value={search}
          onChange={e => setSearch(e.target.value)}
          onSearch={handleSearch}
        />
        {linksItem}
        {username ? (
          <div className={styles.userBox}>
            <div
              className="cursor-pointer flex items-center"
              onClick={() => navigate("/user/account")}
            >
              <img className="rounded-full overflow-hidden w-6 h-6" src={userIcon ? cdnPath + '/' + userIcon : UserIcon} alt="" />
              <div className={styles.username}>{username || "新用户"}</div>
            </div>
            <div
              className={styles.exitBtn}
              onClick={() => setIsModalOpen(true)}
            >
              退出
            </div>
          </div>
        ) : (
          <div className={styles.loginBtn} onClick={() => navigate("/login")}>
            登录
          </div>
        )}
      </div>
      <Modal
        title="退出登录"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => setIsModalOpen(false)}
      >
        <div>是否确定退出登录？</div>
      </Modal>
    </div>
  );
};

export default Index;
