import "./styles/index.scss";
// import "./theme.variable.less"; // 可变主题色
import "./theme.less"; // 固定主题色
import "./index.scss";
import "./antdIndex.scss";
import ReactDOM from "react-dom";
import React from "react";
import { HashRouter } from "react-router-dom";
import Error from "@/pages/error";
import Layout from "@/pages/layout";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import { registerSentry } from "@/utils/sentry-register";
import { PAGE_CONTAINER_ID } from "@/utils/constant";
import webSdk from "wm-tjsdk";

import "dayjs/locale/zh-cn";
import * as dayjs from "dayjs";
dayjs.locale("zh-cn");

if (process.env.MODE && process.env.MODE !== "development") {
  registerSentry({
    dsn: process.env.SENTRY_DNS,
    captureResource: true,
    captureXhr: true
  });
}

function defaultGetContainer() {
  return document.getElementById(PAGE_CONTAINER_ID);
}

window.myAnaly = new webSdk(); //注册对象;
myAnaly.setDebug(false); //打开打点log;
myAnaly.initSDK({
  project: "imepo",
  linkHost: "https://clog.tanshudata.com",
  withAppJsBridge: false,
  updatelog: true
}); //初始化打点;
myAnaly.pageVisted(); //页面访问打点;

if (
  (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.indexOf("iPhone") != -1 ||
    navigator.userAgent.indexOf("iPod") != -1 ||
    navigator.userAgent.indexOf("iPad") != -1) &&
  process.env.MODE === "production"
) {
  window.location.href = "https://m.imepo.cn/#/home";
}
//render
ReactDOM.render(
  <Error>
    <HashRouter>
      {/* antd 使用dayjs 时，影响时间类组件 */}
      {/* <ConfigProvider locale={zhCN} getPopupContainer={defaultGetContainer}> */}
      <ConfigProvider locale={zhCN} >
        <Layout />
      </ConfigProvider>
    </HashRouter>
  </Error>,
  document.getElementById("page-container")
);
