/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: zhengluming
 * @Date: 2023-06-07 17:14:03
 * @LastEditors: zhengluming
 * @LastEditTime: 2023-08-23 19:29:06
 */

import styles from "./index.module.scss";
import React, { useEffect, useMemo } from "react";
import { Modal, Button, Form, Input, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { notifyAPI } from "@/service";
import { useRequest } from "ahooks";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

const antColRight = {
  marginLeft: '24px',
}

const tailLayout = {
  wrapperCol: { span: 24 },
};


const TipOffModal = ({ title = "我要举报", content, open, onCancel, ...modalProps }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);
    submit(values)
  };

  const { loading, run: submit } = useRequest(
    param => notifyAPI.submitTipOff(param),
    {
      manual: true,
      onSuccess: res => {
        if (res.code == 0) {
          message.success('举报提交成功！');
          onCancel()
          form.resetFields()
        } else {
          message.warning(res.message);
        }
      }
    }
  );

  return (
    <Modal
      open={open}
      {...modalProps}
      width={750}
      title={title}
      footer={null}
      onCancel={onCancel}
      closable={true}
      maskClosable={false}
    >
      <div className="pop-center">
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ padding: "0 8px" }}
        >
          <Form.Item name="type" label="举报类型" rules={[{ required: true }]}>
            <Select style={{...antColRight}} options={[
              {label: '色情、有害不良信息', value: 0},
              {label: '政治敏感', value: 1},
              {label: '抄袭侵权', value: 2},
              {label: '章节伪更', value: 3},
            ]}>
            </Select>
          </Form.Item>
          <Form.Item name="link" label="作品/章节链接" rules={[{ required: true }]}>
            <Input style={{...antColRight}}/>
          </Form.Item>
            <Form.Item name="content" label="举报内容" rules={[{ required: true }]}>
              <TextArea style={{...antColRight, minHeight: '210px'}} />
            </Form.Item>
          <Form.Item {...tailLayout} className="m-auto text-center">
            <Button type="primary" htmlType="submit" style={{borderRadius: '16px', width: '80px'}}>
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default TipOffModal;
