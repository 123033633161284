/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: zhengluming
 * @Date: 2023-05-22 17:17:57
 * @LastEditors: zhengluming
 * @LastEditTime: 2023-11-02 15:10:45
 */
import dayjs from "dayjs";

export const timeFormat = UTCDateString => {
  if (!UTCDateString) {
    return "-";
  }
  function formatFunc(str) {
    return str > 9 ? str : "0" + str;
  }
  var date2 = new Date(UTCDateString);
  var year = date2.getFullYear();
  var mon = formatFunc(date2.getMonth() + 1);
  var day = formatFunc(date2.getDate());
  var hour = formatFunc(date2.getHours());
  var min = formatFunc(date2.getMinutes());
  var sec = formatFunc(date2.getSeconds());
  var dateStr =
    year + "/" + mon + "/" + day + " " + hour + ":" + min + ":" + sec;
  return dateStr;
};

export const trimStr = str => {
  return str
    .replace(/<br\/>/g, "<br/>")
    .replace(/<p>\s*/g, "<p>")
    .replace(/<\/p>\s*/g, "</p>")
    .replace(/<h1>\s*/g, "<h1>")
    .replace(/<\/h1>\s*/g, "</h1>")
    .replace(/<h2>\s*/g, "<h2>")
    .replace(/<\/h2>\s*/g, "</h2>")
    .replace(/<h3>\s*/g, "<h3>")
    .replace(/<\/h3>\s*/g, "</h3>")
    .replace(/<h4>\s*/g, "<h4>")
    .replace(/<\/h4>\s*/g, "</h4>")
    .replace(/<h5>\s*/g, "<h5>")
    .replace(/<\/h5>\s*/g, "</h5>")
    .replace(/<table>\s*/g, "<table>")
    .replace(/<\/table>\s*/g, "</table>")
    .replace(/<pre>\s*/g, "<pre>")
    .replace(/<\/pre>\s*/g, "</pre>");
};

export const formatCount = count => {
  if (!count) {
    return 0;
  }
  if (count >= 10000) {
    return (count / 10000).toFixed(2) + "万";
  } else {
    return count;
  }
};
export const formatClicks = count => {
  if (!count) {
    return 0;
  }
  if (count >= 10000) {
    return (count / 10000).toFixed(2) + "万";
  } else {
    return count;
  }
};
export const checkType = (target, type) => {
  let targetType = Object.prototype.toString
    .call(target)
    .toLowerCase()
    .match(/\[object (\S*)\]/)[1];
  return type === undefined ? targetType : targetType === type;
};
// export const appId = TYPE !== "online" ? 10029 : 10028;
export const appId = process.env.MODE == "production" ? 10028 : 10029;

/**
 * '2023-03-30T06:50:27.000+0000'
 * 'YYYY.MM.DD HH:mm:ss'
 */
export const formatDate = (dateString, format = "YYYY.MM.DD HH:mm:ss") => {
  if (!dateString) return "";
  return dayjs(dateString).format(format);
};

/** 钱单位转换：分转换为元 */
export const moneyUnitToYuan = amount => {
  if (typeof amount !== "number" && typeof amount !== "string") return "";
  return Number(amount) / 100;
};
