import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import useGlobalStore from "@/stores";
import { useLoginAuth } from "@/hooks";
import Notify from "@/pages/user-center/notify";

const Index = lazy(() => import("@/pages/index"));
const Login = lazy(() => import("@/pages/login"));
const Search = lazy(() => import("@/pages/search"));
const Product = lazy(() => import("@/pages/product"));
// import Chapter from "@/pages/chapter";
const Chapter = lazy(() => import("@/pages/chapter"));
const UserAgreement = lazy(() => import("@/pages/agreement/user"));
const PersonalAgreement = lazy(() => import("@/pages/agreement/personal"));
const AuthorAgreement = lazy(() => import("@/pages/agreement/author"));
const Recharge = lazy(() => import("@/pages/payment/recharge"));
const UserLayout = lazy(() => import("@/pages/user-center/layout"));
const UserAccount = lazy(() => import("@/pages/user-center/account"));
const UserFinance = lazy(() => import("@/pages/user-center/finance"));
const UserComment = lazy(() => import("@/pages/user-center/comment"));
const UserBookshelf = lazy(() => import("@/pages/user-center/bookshelf"));
const RecommendTicket = lazy(() => import("@/pages/user-center/recommend-ticket"));
const RecommendTicketDetail = lazy(() => import("@/pages/user-center/recommend-ticket/ticket-use-detail"));
const RewardDetail = lazy(() =>
  import("@/pages/user-center/finance/reward/reward-list")
);
const SubscribeDetail = lazy(() =>
  import("@/pages/user-center/finance/subscribe-detail")
);

const Policy = lazy(() => import("@/pages/policy"));

//Do not edit! CDA PREPEND:import
const Page404 = lazy(() => import("@/pages/400/page404"));

/**
 * 路由守卫：部分页面需要登陆后才能访问，不登陆自动跳转登陆页
 */
const AuthGard = ({ children }) => {
  const { isLogin } = useLoginAuth();
  const location = useLocation();
  let nextPage = location.pathname.includes("login") ? "" : location.pathname;
  let loginPath = `/login${nextPage ? `?returnUrl=${nextPage}` : ""}`;

  return isLogin === false ? <Navigate to={loginPath} replace /> : children;
};

const routerList = [
  {
    path: "/",
    element: createSuspense(<Index />)
  },
  {
    path: "/login",
    element: createSuspense(<Login />)
  },
  {
    path: "/userAgreement",
    element: createSuspense(<UserAgreement />)
  },
  {
    path: "/personalAgreement",
    element: createSuspense(<PersonalAgreement />)
  },
  {
    path: "/authorAgreement",
    element: createSuspense(<AuthorAgreement />)
  },
  {
    path: "/search",
    element: createSuspense(<Search />)
  },
  {
    path: "/product/:id",
    element: createSuspense(<Product />)
  },
  {
    path: "/chapter/:id",
    element: createSuspense(<Chapter />)
  },
  {
    path: "/recharge",
    element: createSuspense(
      <AuthGard>
        <Recharge />
      </AuthGard>
    )
  },
  {
    path: "/user",
    element: createSuspense(
      <AuthGard>
        <UserLayout />
      </AuthGard>
    ),
    children: [
      {
        path: "account",
        element: <UserAccount />
      },
      {
        path: "finance",
        element: <UserFinance />
      },
      {
        path: "finance/reward",
        element: <RewardDetail />
      },
      {
        path: "finance/subscribe",
        element: <SubscribeDetail />
      },
      {
        path: "recommend",
        element: <RecommendTicket />
      },
      {
        path: "recommend/detail",
        element: <RecommendTicketDetail />
      },
      {
        path: "comment",
        element: <UserComment />
      },
      {
        path: "bookshelf",
        element: <UserBookshelf />
      },
      {
        path: "notify",
        element: <Notify />
      },
    ]
  },
  {
    path: "/policy",
    element: createSuspense(<Policy />)
  },
  //Do not edit! CDA PREPEND:router
  {
    path: "*",
    element: createSuspense(<Page404 />)
  }
];

function SuspenseElement({ children }) {
  return (
    <Suspense
      fallback={
        <div
          style={{ height: "300px", lineHeight: "300px", textAlign: "center" }}
        >
          <Spin size="large"></Spin>
        </div>
      }
    >
      {children}
    </Suspense>
  );
}

function createSuspense(children) {
  return <SuspenseElement>{children}</SuspenseElement>;
}

function createRedirect(path, to, replace = true) {
  return {
    path,
    element: <Navigate replace={replace} to={to} />
  };
}

const Router = () => useRoutes(routerList);

export default Router;
