/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: zhengluming
 * @Date: 2023-07-11 18:09:37
 * @LastEditors: zhengluming
 * @LastEditTime: 2023-08-23 15:20:20
 */
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { PropertySafetyFilled } from "@ant-design/icons";

import culturePdf from "@/images/culture.pdf";

import TipOffModal from "../modal/tip-off-modal";

export const Index = props => {

  const [tipOffModalShow, setTipOffModalShow] = useState(false);

  return (
    <div
      className={styles.footerContainer}
      style={{ background: props.background, color: props.color }}
    >
      <div><span>客服电话：010-5780 6530</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>客服邮箱：help@imepo.cn</span></div>
      <div>
        <span onClick={() => window.open("https://author.imepo.cn")}>作者专区</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span onClick={() => setTipOffModalShow(true)}>我要举报</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span onClick={() => window.open("https://author.imepo.cn/#/userAgreement")}>咪波文化用户服务协议</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span onClick={() => window.open("https://author.imepo.cn/#/personalAgreement ")}>咪波文化个人信息保护政策</span>
      </div>
      <div>
        <span onClick={() => window.open("https://beian.miit.gov.cn/")}>
          京ICP备2022020811号-3
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span
          onClick={() =>
            window.open(
              "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502039328"
            )
          }
        >
          京公网安备11010502039328号
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span
          onClick={() =>
            window.open(culturePdf)
          }
        >
          京网文[2023]2307-087号
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;© 北京咪波文化科技有限公司版权所有
      </div>
      <TipOffModal open={tipOffModalShow} onCancel={() => setTipOffModalShow(false)} />
    </div>
  );
};

export default Index;
