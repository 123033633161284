/*
 * @Author: ryyyyy
 * @Date: 2022-02-16 17:41:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-02-24 17:03:24
 * @Description: Do not edit
 * @FilePath: /create-data-app/src/utils/request.js
 */
import axios from "axios";

const isProd = process.env.MODE == "production";
axios.defaults.withCredentials = true;
const baseConfig = {
  timeout: 3600000, // 1小时
  baseURL: isProd ? "/api/" : "http://dev-www.imepo.cn/api/",
  withCredentials: true
};

// if (process.env.MODE === "development") {
//   baseConfig.headers["X-SSO-UID"] = "xxx";
// }

const onError = errorMsg => {
  console.log(errorMsg);
  return {
    success: false,
    message: errorMsg,
    data: null
  };
};

const onSuccess = response => {
  if (response.data) {
    return {
      ...response.data,
      success: true
    };
  }

  const responseURL = response.request.responseURL;
  if (process.env.MODE === "development") {
    console.group(responseURL);
    console.error(responseURL);
    console.error(response.data);
    console.groupEnd(responseURL);
  }
  return {
    success: false,
    ...response.data
  };
};

const instance = axios.create(baseConfig);

instance.interceptors.response.use(
  response => onSuccess(response),
  error => onError(error.toString())
);

// 数组转成,链接
function formatParams(params = {}) {
  const r = {};
  Object.keys(params).forEach(key => {
    if (Array.isArray(params[key])) {
      r[key] = params[key].join(",");
    } else {
      r[key] = params[key];
    }
  });
  return r;
}

function jsonToFormData(json) {
  let fd = new FormData();
  for (let i in json) {
    fd.append(i, json[i]);
  }
  return fd;
}

const request = {
  get: (url, params = {}) => {
    url =
      url.includes("account") || url.includes("captcha")
        ? url
        : `pc/imepo/${url}`;
    return instance.get(url, {
      params: formatParams(params)
    });
  },
  post: (url, data = {}) => {
    url =
      url.includes("account") || url.includes("captcha")
        ? url
        : `pc/imepo/${url}`;
    return instance.post(url, jsonToFormData(data), {});
  }
};

// 普通接口
export default request;

const downloadInstanse = axios.create(baseConfig);

export const downloadRequest = {
  post: (url, data) => {
    return downloadInstanse.request({
      method: "post",
      url: url,
      data: data,
      responseType: "blob"
    });
  },
  get: (url, params = {}) => {
    return downloadInstanse.request({
      method: "get",
      url: url,
      params: formatParams(params),
      responseType: "blob"
    });
  }
};
